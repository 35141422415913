<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Nuevo Vehículo</span>
          </v-card-title>
          <v-card-text>
            <p v-if="responseError && !valid">
              Los datos enviados no son correctos, haga click sobre la X de los
              campos inválidos para intentar de nuevo.
            </p>
            <v-container>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Código"
                      v-model="body.code"
                      :rules="onlyRequired"
                      @clearable="codeError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="categories"
                      v-model="body.category_id"
                      item-text="name"
                      item-value="id"
                      label="Categoría"
                      :rules="onlyRequired"
                      @clearable="categoryError = []"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="manufacturers"
                      v-model="body.manufacturer_id"
                      item-text="name"
                      item-value="id"
                      label="Marca"
                      :rules="onlyRequired"
                      @clearable="manufacturerError = []"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Línea"
                      v-model="body.model"
                      :rules="onlyRequired"
                      @clearable="modelError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Placa"
                      v-model="body.vehicleid"
                      :error-messages="vehicleidError"
                      :rules="onlyRequired"
                      @clearable="vehicleidError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Año"
                      type="year"
                      v-model="body.year"
                      :rules="onlyRequired"
                      @clearable="yearError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save()"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import request from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  components: {},
  props: {
    dialog: { type: Boolean, required: true },
  },
  data: () => ({
    valid: false,
    loading: false,
    responseError: false,
    codeError: [],
    categoryError: [],
    manufacturerError: [],
    modelError: [],
    vehicleidError: [],
    yearError: [],
    categories: [],
    manufacturers: [],
    body: {
      code: undefined,
      category_id: undefined,
      manufacturer_id: undefined,
      model: undefined,
      vehicleid: undefined,
      year: undefined,
    },
    //rules
    onlyRequired: [rules.required],
  }),
  methods: {
    save() {
      this.valid = false;
      this.loading = true;
      if (this.$refs.form.validate()) {
        request.post("api/catalogs/vehicles/", this.body).then((res) => {
          if (res.status == 201) {
            this.close();
            this.$emit("refresh");
          } else if (res.status == 400) {
            this.responseError = true;

            if (res.data.code != undefined) {
              this.codeError = res.data.code;
            }
            if (res.data.category_id != undefined) {
              this.categoryError = res.data.category_id;
            }
            if (res.data.manufacturer_id != undefined) {
              this.manufacturerError = res.data.manufacturer_id;
            }
            if (res.data.model != undefined) {
              this.modelError = res.data.model;
            }
            if (res.data.vehicleid != undefined) {
              this.vehicleidError = res.data.vehicleid;
            }
            if (res.data.year != undefined) {
              this.yearError = res.data.year;
            }
          }
        });
      }
      this.loading = false;
    },
    close() {
      this.body = {
        code: undefined,
        category_id: undefined,
        manufacturer_id: undefined,
        model: undefined,
        vehicleid: undefined,
        year: undefined,
      };
      this.responseError = false;
      this.codeError = [];
      this.categoryError = [];
      this.manufacturerError = [];
      this.modelError = [];
      this.vehicleidError = [];
      this.yearError = [];
      this.categories = [];
      this.manufacturers = [];
      this.$emit("close");
      this.$refs.form.reset();
    },
    getCategory() {
      request.get("api/catalogs/vehicle/category").then((res) => {
        this.categories = res.data;
      });
    },
    getManufacturer() {
      request.get("api/catalogs/vehicle/manufacturer").then((res) => {
        this.manufacturers = res.data;
      });
    },
  },
  mounted() {
    this.getCategory();
    this.getManufacturer();
  },
};
</script>